import React from 'react';
import './HowItWorks.css';

const HowItWorks: React.FC = () => {
    return (
        <section className="outer-how-it-works-container">
            <div className="how-it-works-container">
                <h2 className="how-it-works-title">How It Works</h2>
                <div className="steps-container">
                    <div className="step">
                        <div className="step-number">1</div>
                        <p className="step-description">
                            Enter the URL you want to shorten in the input field above.
                        </p>
                    </div>
                    <div className="step">
                        <div className="step-number">2</div>
                        <p className="step-description">
                            Choose encoding algorithm: Hashing or Custom
                        </p>
                    </div>
                    <div className="step">
                        <div className="step-number">3</div>
                        <p className="step-description">
                            Click "Shorten My Link" to generate your shortened URL.
                        </p>
                    </div>
                    <div className="step">
                        <div className="step-number">4</div>
                        <p className="step-description">
                            Copy the shortened URL and share it anywhere!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
