import React from "react";
import './Navbar.css'
import {useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const Navbar: React.FC = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate()
    const handleHomeClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault(); // Prevent default anchor behavior
        window.location.reload(); // Hard reset the page
    };

    const handleDonateButton = () => {
        window.open(
            'https://www.paypal.com/donate/?business=9AM4BZK3DFKT6&no_recurring=0&item_name=Thank+you+for+support%21+New+features+and+exciting+functionality+is+on+the+way%21&currency_code=USD',
            '_blank'
        );
    }

    const authAction = user ? (
        <li><span onClick={logout} aria-label="Sign out of your account">Sign Out</span></li>
    ) : (
        <li><span onClick={() => navigate("/login")} aria-label="Sign in to your account"> Sign In</span></li>
    )
    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li><a href="/" onClick={handleHomeClick}>Home</a></li>
                {/*<li><a className="disabled-link" href="#features">Features</a></li>*/}
                <li><span onClick={handleDonateButton} aria-label="Donate button">Donate</span></li>
                {/*<li><a className="disabled-link" href="#pricing" onClick={(e) => e.preventDefault()}>Pricing</a></li> /!* Disabled *!/*/}
                {/*<li><a href="#contact">Contact</a></li>*/}
                {authAction}
            </ul>
        </nav>
    )
}

export default Navbar;