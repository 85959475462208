import React from "react";
import Header from "../../components/header/Header";
import ShortenUrlContainer from "../../components/shorten-url-section/ShortenUrlContainer";
import HowItWorks from "../../components/how-it-works/HowItWorks";
import DonateButton from "../../components/donate-button/DonateButton";
import Footer from "../../components/footer/Footer";

const Home: React.FC = () => {
    return (
        <>
            <Header/>
            <ShortenUrlContainer/>
            <HowItWorks/>
            <DonateButton />
            <Footer/>
        </>
    )
}

export default Home