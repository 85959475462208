import React from "react";
import './RadioOptions.css'
import {ENCODING_TYPE} from "../../../constants/constants";

interface RadioOptions {
    setChecked: (value: string) => void;
}

const RadioOptions: React.FC<RadioOptions> = ({setChecked}) => {
    return (
        <div className="radio-options">
            <label>
                <input
                    type="radio"
                    name="encodingMethod"
                    value="hash"
                    onChange={() => setChecked(ENCODING_TYPE.HASH)}
                />
                <span className="radio-custom"></span>
                <span className="algorithm-choice">HASH</span>
            </label>
            <label>
                <input
                    type="radio"
                    name="encodingMethod"
                    value="custom"
                    onChange={() => setChecked(ENCODING_TYPE.CUSTOM)}
                />
                <span className="radio-custom"></span>
                <span className="algorithm-choice">CUSTOM</span>
            </label>
        </div>
    )
}

export default RadioOptions;