import React from 'react';
import './ShortenButton.css';

interface ButtonProps {
    size?: 'small' | 'medium' | 'large',
    children: React.ReactNode,
    onClick?: () => void
    className?: string,
}

const ShortenButton: React.FC<ButtonProps> = ({size = 'medium', children, onClick, className}) => {
    const sizeClass = `shorten-btn ${size} ${className || ''}`;
    return (
        <button onClick={onClick} className={sizeClass}>{children}</button>
    );
};


export default ShortenButton;
