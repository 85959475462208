import React from "react";
import './ErrorInputMessage.css'

interface ErrorInputMessageProps {
    message: string;
}
const ErrorInputMessage: React.FC<ErrorInputMessageProps> = ({message}) => {
    return (
        <p className="error-message">{message}</p>
    )
}

export default ErrorInputMessage;