import axios from "axios";
import {ENCODING_TYPE} from "../constants/constants";

interface UrlEncodeRequest {
    url: string;
    encodingType: string
}

interface UrlEncodeCustomRequest extends UrlEncodeRequest {
    customName: string
}


const sendUrlForHashEncoding = async (urlToEncode: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/encode`;
    const body: UrlEncodeRequest = {
        url: urlToEncode,
        encodingType: ENCODING_TYPE.HASH,
    };
    return await axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

const sendCustomUrlForEncoding = async (urlToEncode: string, path: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/encode/custom`;
    const body: UrlEncodeCustomRequest = {
        url: urlToEncode,
        encodingType: ENCODING_TYPE.CUSTOM,
        customName: path,
    };
    return await axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

const sendRequestToUrlManager = async (urlToEncode: string, encodingType: string, path: string = '') => {
    if (encodingType === ENCODING_TYPE.HASH) {
        return await sendUrlForHashEncoding(urlToEncode);
    } else {
        return await sendCustomUrlForEncoding(urlToEncode, path);
    }
}

export const urlManagerService = {
    sendRequest: sendRequestToUrlManager,
}
