import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDtku4PfWu_r5fw0LOQ3eMBM77Xqwq9W7A",
    // TODO: Change auth domain to custom one once purchased.
    authDomain: "shortr.one",
    projectId: "shortr-298b5",
    storageBucket: "shortr-298b5.firebasestorage.app",
    messagingSenderId: "357450110171",
    appId: "1:357450110171:web:7c0227f235f3d3373da8fe",
    measurementId: "G-LKKVSXG1E8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

// Google Provider
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app);

// if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
//     console.log("Connecting to Functions emulator...");
//     connectFunctionsEmulator(functions, "localhost", 5001); // Replace 5001 with your emulator's port
// }

export { auth, googleProvider, functions };