import React from "react";

import AuthForm from "../../components/auth-form/AuthForm";

const Login: React.FC = () => {
    return (
        <>
            <AuthForm/>
        </>
    )
}

export default Login