import React from 'react';
import './InputGroup.css';

interface InputGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    labelTitle: string;
    inputId: string;
}

const InputGroup: React.FC<InputGroupProps> = ({
                                                   className = '',
                                                   labelTitle,
                                                   inputId,
                                                   ...props
                                               }) => {
    return (
        <div className={`input-group ${className}`}>
            <label htmlFor={inputId} className="input-label">
                {labelTitle}
            </label>
            <input id={inputId} className="input-field" {...props} />
        </div>
    );
};

export default InputGroup;
