import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-brand">
                    <h2>Shortr</h2>
                    {/*<p>Simplify your links, manage your URLs, and enhance your online presence.</p>*/}
                </div>
                <div className="footer-social">
                    <a href="https://www.linkedin.com/in/glebgalkin/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href="https://github.com/glebgalkin/shortr-docs" target="_blank" rel="noopener noreferrer">Github</a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Shortr. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
