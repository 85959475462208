import React, {useState} from "react";
import "./AuthForm.css";
import google from '../../assets/icons/google-icon.png';
import microsoft from '../../assets/icons/microsoft.png';
import appleWhite from '../../assets/icons/apple-white.png';
import appleBlack from '../../assets/icons/apple-black.png';
import ShortenButton from "../reusable/buttons/ShortenButton";
import InputGroup from "../reusable/input-group/InputGroup";
import ProviderButton from "./provider-button/ProviderButton";
import {auth} from "../../config/firebase";
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import {useNavigate} from "react-router-dom";

const AuthForm: React.FC = () => {
    const navigate = useNavigate();
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('')

    const toggleForm = () => {
        setIsSignUp((prev) => !prev);
        setErrorMessage('');
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            navigate('/')

        } catch (error) {
            console.error("Google Sign-In Error:", error);
        }
    }

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            if (isSignUp) {
                await createUserWithEmailAndPassword(auth, email, password);
            } else {
                await signInWithEmailAndPassword(auth, email, password);
            }
            navigate('/');
        } catch (error: any) {
            console.error("Auth Error:", error);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="auth-form-container">
            <div className="auth-form">
                <h2>{isSignUp ? "Create your account" : "Sign in"}</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleFormSubmit}>
                    {isSignUp && (
                        <InputGroup
                            labelTitle="Name"
                            inputId="name"
                            type="text"
                            placeholder="Your full name"
                        />
                    )}
                    <InputGroup
                        labelTitle="Email address"
                        inputId="email"
                        type="email"
                        placeholder="john.doe@mail.com"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputGroup
                        className="last-label"
                        labelTitle="Password"
                        inputId="password"
                        type="password"
                        placeholder="Enter your password"
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <ShortenButton className="auth-button" size="small">
                        {isSignUp ? "Continue" : "Sign In"}
                    </ShortenButton>
                </form>


                <div className="auth-options">
                    {isSignUp ? (
                        <>
                            <p>
                                Already have an account?{" "}
                                <span onClick={toggleForm} className="toggle-link"> Log in</span>
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                Don't have an account?{" "}
                                <span onClick={toggleForm} className="toggle-link"> Sign up </span>
                            </p>
                        </>
                    )}

                    {/*<div className="divider">OR</div>*/}
                    {/*<ProviderButton src={google} alt='Google Logo' onClick={handleGoogleSignIn}>Continue with Google</ProviderButton>*/}
                    {/*<ProviderButton src={microsoft} alt='Microsoft Logo' onClick={}>Continue with Microsoft</ProviderButton>*/}
                    {/*<ProviderButton src={appleWhite} alt='Apple Logo'>Continue with Apple</ProviderButton>*/}
                </div>
            </div>
        </div>
    );
};

export default AuthForm;
