import React from 'react';
import './DonateButton.css';

const DonateButton: React.FC = () => {
    const handleDonateClick = () => {
        window.open(
            'https://www.paypal.com/donate/?business=9AM4BZK3DFKT6&no_recurring=0&item_name=Thank+you+for+support%21+New+features+and+exciting+functionality+is+on+the+way%21&currency_code=USD',
            '_blank'
        );
    };

    return (
        <button className="donate-button" onClick={handleDonateClick}>
            ❤️ Donate
        </button>
    );
};

export default DonateButton;
