import React, {useState} from 'react';
import './ShortenUrlContainer.css';
import ShortenButton from '../reusable/buttons/ShortenButton';
import LoadingSpinner from "../reusable/loading/LoadingSpinner";
import InputBar from './input-bar/InputBar';
import CopyResultContainer from "./copy-result-container/CopyResultContainer";
import {urlManagerService} from "../../service/url-manager-service";
import axios from "axios";
import ErrorInputMessage from "./error-input-message/ErrorInputMessage";
import {useAuth} from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {CUSTOM_ENCODING_PLACEHOLDER, ENCODING_PLACEHOLDER, ENCODING_TYPE} from "../../constants/constants";
import RadioOptions from "./radio-options/RadioOptions";

const ShortenUrlContainer: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [originalUrl, setOriginalUrl] = useState('');
    const [shortenedUrl, setShortenedUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [radioChoice, setRadioChoice] = useState(ENCODING_TYPE.HASH);
    const [customUrl, setCustomUrl] = useState('');


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) {
            navigate('/login');
            return;
        }
        if (originalUrl.trim() === '') return;

        setIsLoading(true);
        await sleep(2000);

        await urlManagerService.sendRequest(originalUrl, radioChoice, customUrl).then(response => {
            setIsLoading(false);
            setShortenedUrl(`${process.env.REACT_APP_API_BASE_URL}/${response.data.encodedUrl}`);
        }).catch(error => {
            setIsLoading(false);
            if (axios.isAxiosError(error) && error.response) {
                setErrorMessage(error.response.data.message)
            } else {
                setErrorMessage("Something went wrong. Please try again later.")
            }
        })
    };

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const resetUrl = () => {
        setShortenedUrl('');
        setOriginalUrl('');
    }

    return (
        <div className="shorten-url-outer-container">
            <div className="inner-shorten-url-container">
                {isLoading ? (
                    <LoadingSpinner/>
                ) : (
                    <>
                        <h2>Shorten Your URL</h2>
                        {shortenedUrl ? <CopyResultContainer shortenedUrl={shortenedUrl} resetUrl={resetUrl}/> :
                            <>
                                <form className="input-container" onSubmit={handleSubmit}>
                                        <InputBar
                                            placeholder = {ENCODING_PLACEHOLDER}
                                            value={originalUrl}
                                            onChange={setOriginalUrl}
                                        />
                                    {radioChoice === ENCODING_TYPE.CUSTOM
                                        && <InputBar
                                            placeholder={CUSTOM_ENCODING_PLACEHOLDER}
                                            value={customUrl}
                                            onChange={setCustomUrl}
                                            className="custom-width"
                                        />}
                                        <RadioOptions setChecked={setRadioChoice} />
                                        {errorMessage && <ErrorInputMessage message={errorMessage}/>}
                                    <ShortenButton>Shorten My Link</ShortenButton>
                                </form>
                            </>
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default ShortenUrlContainer;
