import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="loading-spinner-container">
            <div className="rotating-circle">
                <div className="center-icon"></div> {/* Shorts icon */}
            </div>
        </div>
    );
};

export default LoadingSpinner;
