import React from 'react';
import './Inputbar.css'

interface InputBarProps {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

const InputBar: React.FC<InputBarProps> = ({ placeholder, value, onChange, className }) => {
    return (
        <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)} // Pass changes back to parent
            placeholder={placeholder}
            className={`url-input ${className || ''}`}
        />
    );
};

export default InputBar;
