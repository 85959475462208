import React from 'react';
import Navbar from './navbar/Navbar';
import './Header.css';
import logo from '../../assets/icons/shortr-high-resolution-logo-transparent.png';

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="header-left">
                <img className = "header-logo"
                     src={logo}
                     alt="Shortr Logo"
                     onClick={() => window.location.reload()}
                />
            </div>
            <div className="header-right">
            <Navbar />
            </div>
        </header>
    );
};

export default Header;
