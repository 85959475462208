import React, {useState} from "react";
import './CopyResultContainer.css';
import ShortenButton from "../../reusable/buttons/ShortenButton";

interface CopyResultContainerProps {
    shortenedUrl: string;
    resetUrl: () => void;
}

const CopyResultContainer: React.FC<CopyResultContainerProps> = ({shortenedUrl, resetUrl}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(shortenedUrl);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };

    return (
        <>
            <div className="shortened-result">
                <input
                    type="text"
                    value={shortenedUrl}
                    readOnly
                    className="shortened-url-input"
                />
                <button
                    onClick={handleCopy}
                    className={`copy-btn ${isCopied ? 'copied' : ''}`}
                >
                    {isCopied ? "Copied" : "Copy Link"}
                </button>
                {isCopied && <p className="copy-message">Link copied to clipboard!</p>}
            </div>
            <ShortenButton onClick={resetUrl}> Another one</ShortenButton>
        </>
    );
};

export default CopyResultContainer;
